import { NavLink } from 'react-router-dom'
import KyQuay from './KyQuay'
import { useEffect, useState } from 'react'
import { IGame } from '@/shared/model/game.model'
import axios from '../../../shared/config/axios-interceptor'

interface IBoxKyQuayHeader {
  load: boolean
  ky?: any
  setLoad: any
  game?: any
}

const BoxKyQuayHeader = (props: IBoxKyQuayHeader) => {
  const { game, load, ky, setLoad } = props

  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null)
  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get(
        '/draws/get-result-ket-qua-ky-truoc/' + 1,
      )
      setKetQuaKyTruoc(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getKetQuaKyTruoc()
  }, [game])
  return (
    <div className="content-header justify-content-between">
      <div
        className="d-flex gap-2 align-items-center ms-2"
        style={{ width: 220 }}
      >
        <img
          width={60}
          height={60}
          src={game?.imageDesktop}
        />
        <h3 style={{ fontSize: 22, color: '#6d4f84' }} className="fw-bold m-0">
          {game?.name}
        </h3>
      </div>

      <KyQuay
        load={load}
        ky={ky}
        setLoad={setLoad}
      />

      <div className="d-flex flex-column">
        <div className="fw-bold" style={{ color: '#000', fontSize: 12 }}>
          kỳ: {ketQuaKyTruoc?.id}
        </div>
        <div className="d-flex item-bi-quay">
          {Array.from({ length: game?.bi ?? 10 }, (_, i) => i + 1).map(
            (_, index) => {
              const propertyName: any = `ball${index + 1}`
              const ball = ketQuaKyTruoc?.[propertyName as keyof IGame] ?? 0

              // Kiểm tra và xử lý giá trị ball nếu cần
              let ballValue: React.ReactNode = null
              if (typeof ball === 'number' || typeof ball === 'string') {
                ballValue = ball
              } else {
                ballValue = '' // hoặc giá trị mặc định phù hợp
              }
              return <div key={index} className={'item item-' + ball}>{ballValue}</div>
            },
          )}
        </div>
      </div>

      <div className="action-btn mx-3">
        <div className="item" style={{ marginBottom: 20 }}>
          <NavLink
            className="text-decoration-none text-white"
            to={'/lich-su-dat-cuoc'}
          >
            Lịch sử tham gia
          </NavLink>
        </div>
        <div className="item">
          <NavLink
            className="text-decoration-none text-white"
            to={'/lich-su-dat-cuoc'}
          >
            Lịch sử giao dịch
          </NavLink>
        </div>
      </div>
    </div>
  )
}
export default BoxKyQuayHeader
