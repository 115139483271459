import { RootState } from '@/reducers';
import { socket } from '@/shared/config/socket';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { AppDispatch } from '../../store';
import TheAside from './TheAside';
import TheContent from './TheContent';
import TheHeader from './TheHeader';
import TheSidebar from './TheSidebar';
import { setBodyWidth } from './reducer';
import axios from '../../shared/config/axios-interceptor'
import noti from '../../assets/noti.mp3'

const TheLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { ref: bodyRef, width: bodyWidth } = useResizeDetector();
  const { user } = useSelector((state: RootState) => state.authentication);
  const audioRef: any = useRef(null)
  useEffect(() => {
    dispatch(setBodyWidth(bodyWidth || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyWidth]);

  useEffect(() => {
    const interval = setInterval(() => {
      socket.emit("send_notify");
    }, 1000);

    const handleNotify = (data: any) => {
      if(data){
        audioRef.current.play().catch((error: any) => {
          console.log('Audio play failed:', error)
        })
      }
      console.log("Connected to server", data);
    };

    socket.on("send_notify", handleNotify);

    return () => {
      clearInterval(interval);
      socket.off("send_notify", handleNotify);
    };
  }, [socket]);


  // const getNotSeen = async () => {
  //   try {
  //     const response = await axios.get('/room/not-seen')
  //     if (response?.data) {
  //       audioRef.current.play().catch((error: any) => {
  //         console.log('Audio play failed:', error)
  //       })
  //     }
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }


  // const getNotSeenHistory = async () => {
  //   try {
  //     const response = await axios.get('/transaction/not-seen')
  //     if (response?.data) {
  //       audioRef.current.play().catch((error: any) => {
  //         console.log('Audio play failed:', error)
  //       })
  //     }
  //   } catch (error) {
  //     console.error('Error:', error)
  //   }
  // }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getNotSeen()
  //     getNotSeenHistory()
  //   }, 1000)
  //   return () => clearInterval(interval)
  // }, [])


  return (
    <div>
      <audio ref={audioRef} src={noti} />
      <TheAside />
      <TheSidebar />

      <div className="wrapper d-flex flex-column min-vh-100 bg-light" ref={bodyRef}>
        <TheHeader />

        <div className="body flex-grow-1">
          <TheContent />
        </div>
        {/* <TheFooter /> */}
      </div>
    </div>
  );
};
export default TheLayout;
