import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

interface IData {
  setLoad: any
  load: boolean
  ky: IGame | null
}
const KyQuay = (props: IData) => {
  const { setLoad, load, ky } = props

  const [timeInterval, setTimeInterval] = useState<any>({
    minutes: '00',
    seconds: '00',
  })

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(2, 'seconds')

    const interval = setInterval(() => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)

      if (difference > 0) {
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60),
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        let m = minutes < 10 ? '0' + minutes : minutes
        let s = seconds < 10 ? '0' + seconds : seconds
        setTimeInterval({ minutes: m, seconds: s })
      } else {
        setLoad(!load)
        setTimeInterval('Đang chờ kết quả')
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [ky?.endTime, load])

  const [listM, setListM] = useState({
    list1: [5, 4, 3, 2, 1, 0],
    list2: [5, 4, 3, 2, 1, 0],
  })

  const [listS, setListS] = useState({
    list1: [9, , 8, 7, 6, 5, 4, 3, 2, 1, 0],
    list2: [9, , 8, 7, 6, 5, 4, 3, 2, 1, 0],
  })

  return (
    <>
      <div className="box-ky-quay-custom text-center mt-3">
        <div className="flip-clock-wrapper">
          <div className="u_f--left hours">
            <ul className="flip">
              <li className="flip-clock-active">
                <a>
                  <div className="up">
                    <div className="shadow" />
                    <div className="inn">0</div>
                  </div>
                  <div className="down">
                    <div className="shadow" />
                    <div className="inn">0</div>
                  </div>
                </a>
              </li>
            </ul>
            <ul className="flip">
              <li className="flip-clock-active">
                <a>
                  <div className="up">
                    <div className="shadow" />
                    <div className="inn">0</div>
                  </div>
                  <div className="down">
                    <div className="shadow" />
                    <div className="inn">0</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="u_f--left minutes">
            <ul className="flip" style={{ width: 20 }}>
              <li>
                <a>
                  <div className="up">
                    <div className="shadow" />
                    <div className="inn">:</div>
                  </div>
                  <div className="down">
                    <div className="shadow" />
                    <div className="inn">:</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="u_f--left minutes">
            <ul className="flip">
              {listM.list1.map((item) => {
                let classA = ''
                if (timeInterval?.minutes) {
                  const seconds2 = timeInterval?.minutes.toString().split('')[0]
                  if (Number(seconds2) == 5) {
                    if (0 == item) {
                      classA = 'flip-clock-before'
                    }
                  } else {
                    if (Number(seconds2) + 1 == item) {
                      classA = 'flip-clock-before'
                    }
                  }

                  if (seconds2 == item) {
                    classA = 'flip-clock-active'
                  }
                }

                return (
                  <li className={classA} key={item}>
                    <a>
                      <div className="up">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                      <div className="down">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
            <ul className="flip play">
              {listM.list2.map((item) => {
                let classA = ''
                if (timeInterval?.minutes) {
                  const seconds2 = timeInterval?.minutes.toString().split('')[1]
                  if (Number(seconds2) == 5) {
                    if (0 == item) {
                      classA = 'flip-clock-before'
                    }
                  } else {
                    if (Number(seconds2) + 1 == item) {
                      classA = 'flip-clock-before'
                    }
                  }

                  if (seconds2 == item) {
                    classA = 'flip-clock-active'
                  }
                }

                return (
                  <li className={classA} key={item}>
                    <a>
                      <div className="up">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                      <div className="down">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="u_f--left minutes">
            <ul className="flip" style={{ width: 20 }}>
              <li>
                <a>
                  <div className="up">
                    <div className="shadow" />
                    <div className="inn">:</div>
                  </div>
                  <div className="down">
                    <div className="shadow" />
                    <div className="inn">:</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="u_f--left seconds">
            <ul className="flip play">
              {listS.list1.map((item) => {
                let classA = ''
                if (timeInterval?.seconds) {
                  const seconds2 = timeInterval?.seconds.toString().split('')[0]
                  if (Number(seconds2) == 9) {
                    if (0 == item) {
                      classA = 'flip-clock-before'
                    }
                  } else {
                    if (Number(seconds2) + 1 == item) {
                      classA = 'flip-clock-before'
                    }
                  }

                  if (seconds2 == item) {
                    classA = 'flip-clock-active'
                  }
                }

                return (
                  <li className={classA} key={item}>
                    <a>
                      <div className="up">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                      <div className="down">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
            <ul className="flip play">
              {listS.list2.map((item) => {
                let classA = ''
                if (timeInterval?.seconds) {
                  const seconds2 = timeInterval?.seconds.toString().split('')[1]
                  if (Number(seconds2) == 9) {
                    if (0 == item) {
                      classA = 'flip-clock-before'
                    }
                  } else {
                    if (Number(seconds2) + 1 == item) {
                      classA = 'flip-clock-before'
                    }
                  }

                  if (seconds2 == item) {
                    classA = 'flip-clock-active'
                  }
                }

                return (
                  <li className={classA} key={item}>
                    <a>
                      <div className="up">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                      <div className="down">
                        <div className="shadow" />
                        <div className="inn">{item}</div>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default KyQuay
