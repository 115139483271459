import { RootState } from "@/reducers";
import { IGame } from "@/shared/model/game.model";
import { AppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../shared/config/axios-interceptor";
import { getProfile } from "../auth/auth.api";
import { formatVND } from "@/shared/utils/ultils";
import { CCol, CForm, CRow } from "@coreui/react-pro";
import { IBiDatCuoc } from "@/shared/model/biDatCuoc.model";
import {
  ToastErrorBottom,
  ToastSuccessBottom,
} from "@/components/shared/toast/Toast";
import KyQuay from "./KyQuay";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { getEntities } from "../HistoryManagement/historyManagement.api";
import { historySelectors } from "../HistoryManagement/historyManagement.reducer";
import { IGameName } from "@/shared/model/gameName.model";
import { IGameSidebar } from "@/shared/model/gameSidebar.model";
import logoWeb from "../../../assets/img/logo-9.gif";
import { Formik } from "formik";
import BoxKetQua from "./BoxKetQua";
import dayjs from "dayjs";
import { HistoryState } from "@/shared/enumeration/historyState";

const Pk10Custom = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { navigate } = useRouter();
  const [soTien, setSoTien] = useState<number>(100);
  const [boxCuoc, setBoxCuoc] = useState<IGameSidebar[]>([]);
  const [ketQuaKyTruoc, setKetQuaKyTruoc] = useState<IGame | null>(null);
  const [ky, setKy] = useState<IGame | null>(null);
  const [load, setLoad] = useState(false);
  const { user } = useSelector((state: RootState) => state.authentication);
  const historys = useSelector(historySelectors.selectAll);

  const { initialState } = useSelector(
    (state: RootState) => state.historyReducer
  );
  const { filterState } = initialState;

  const getBoxCuoc = async () => {
    try {
      const response = await axios.get("/bidatcuoc/listcuoc/1");
      setBoxCuoc(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getKy = async () => {
    try {
      const response = await axios.get("/draws/get-current/1");
      setKy(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getKetQuaKyTruoc = async () => {
    try {
      const response = await axios.get("/draws/get-result-ket-qua-ky-truoc/1");
      setKetQuaKyTruoc(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getBoxCuoc();
    getKy();
    getKetQuaKyTruoc();
    dispatch(getProfile());
    dispatch(
      getEntities({
        ...filterState,
        userId: Number(user?.id) ?? 1,
        sortBy: "historyId",
        sortOrder: "DESC",
        status: null,
        state: HistoryState.SHOW,
      })
    );
  }, [load]);

  const initialValues: any = {};

  return (
    <div className="page-wap-game d-flex flex-column">
      <div className="home_top">
        <div className="w-100" style={{ color: "#fff" }}>
          Số dư: {formatVND(user?.money ?? 0)}
        </div>
        <div className="w-100 home_top-left text-center">
          <div onClick={() => navigate("/")} className="home_top-logo soc">
            <img height={70} src={logoWeb} alt="logo" />
          </div>
        </div>
        <div className="w-100 home_top-right text-end">
          <div
            onClick={() => navigate("/account")}
            className="home_top-kf icon-kf"
            style={{ color: "#fff" }}
          >
            {user?.username}
          </div>
        </div>
      </div>

      <div className="px-3 mt-4">
        <CRow className="box-top g-3">
          <CCol xs={6}>
            <div className="left h-100">
              <img className="w-100" src={logoWeb} />
            </div>
          </CCol>
          <CCol xs={6}>
            <div className="left h-100">
              <div className="fw-bold text-center">
                Thời gian đặt cược{" "}
                <span style={{ color: "red" }}>phiên kế tiếp</span>
              </div>
              <KyQuay
                load={load}
                ky={ky}
                setLoad={setLoad}
              />
            </div>
          </CCol>
        </CRow>
      </div>

      <BoxKetQua ky={ky} ketQuaKyTruoc={ketQuaKyTruoc} />

      <div className="main-content-game">
        <div className="tab tab1 px-3 pt-3">
          <div className="row g-3 pb-5">
            {boxCuoc.map((item, index) => {
              return (
                <CCol xs={6} key={index}>
                  <div className="item-box-dat-cuoc-1">
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      onSubmit={async (values, { resetForm }) => {
                        let listData: IBiDatCuoc[] = [];
                        let total = 0;

                        item.biDatCuoc.forEach((it, id) => {
                          const propertyName: any =
                            values[`bien_${it.biDatCuocId}`];
                          if (propertyName && Number(propertyName) > 0) {
                            total = total + Number(propertyName);
                            listData.push({
                              ...it,
                              amount: Number(propertyName),
                            });
                          }
                        });

                        if (total > (user?.money ?? 0)) {
                          ToastErrorBottom("Số dư không đủ");
                          return;
                        }

                        if (Number(total) > Number(user?.money ?? 0)) {
                          ToastErrorBottom("Số dư không đủ");
                          return;
                        }

                        if (Number(total) <= 0) {
                          ToastErrorBottom("Số tiền không hợp lệ");
                          return;
                        }

                        try {
                          const data = {
                            money: soTien,
                            kyId: ky?.id,
                            listDatCuoc: listData,
                          };
                          const response = await axios.post(
                            "/history/create-all-web",
                            data
                          );
                          ToastSuccessBottom("Đặt cược thành công");
                          resetForm();
                          dispatch(getProfile());
                        } catch (error) {
                          console.log(error);
                          resetForm();
                        }
                      }}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                      }) => (
                        <CForm className="p-0" onSubmit={handleSubmit}>
                          <div className="title">{item.name}</div>
                          <div className="tab-header">
                            <div className="name-title">Con số</div>
                            <div className="rate-title">Tỷ lệ</div>
                            <div className="amount-title">Số lượng</div>
                          </div>

                          <div className="box-content">
                            {item.biDatCuoc.map((it, id) => {
                              return (
                                <div
                                  key={id}
                                  className="mb-2 d-flex align-items-center"
                                >
                                  <div className="name">{it.name}</div>
                                  <div className="rate">{it.rate}</div>
                                  <div className="input">
                                    <input
                                      name={`bien_${it.biDatCuocId}`}
                                      value={
                                        values[`bien_${it.biDatCuocId}`] ?? ""
                                      }
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        if (/^\d*$/.test(value)) {
                                          setFieldValue(
                                            `bien_${it.biDatCuocId}`,
                                            value
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            })}

                            <button
                              disabled={isSubmitting}
                              type="submit"
                              className="btn-submit-1 mt-3"
                            >
                              XÁC NHẬN ĐẶT
                            </button>
                          </div>
                        </CForm>
                      )}
                    </Formik>
                  </div>
                </CCol>
              );
            })}
          </div>

          <h3 className="fw-bold text-center mb-3">Danh sách đặt cược</h3>
          <div className="table-history-wap">
            <div className="header-table d-flex">
              <div>Kỳ</div>
              <div>Giải</div>
              <div>Loại cược</div>
              <div>Tỉ lệ</div>
              <div>Tiền cược</div>
              <div>Trả thưởng</div>
              <div>Thời gian</div>
            </div>

            {historys.map((item, index) => {
              return (
                <div className="content-table d-flex" key={index}>
                  <div>{item.KyId}</div>
                  <div>{item?.biDatCuoc?.gameSidebar?.name}</div>
                  <div>{item?.biDatCuoc?.name}</div>
                  <div>{item?.rate}</div>
                  <div>{formatVND(item?.money ?? 0)}</div>
                  <div>{formatVND(item?.addMoney ?? 0)}</div>
                  <div>
                    {dayjs(item?.createdDate).format("DD/MM/YYYY HH:mm:ss")}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5"></div>
      </div>
    </div>
  );
};

export default Pk10Custom;
