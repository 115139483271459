import { RootState } from '@/reducers'
import { IGame } from '@/shared/model/game.model'
import { AppDispatch } from '@/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../../shared/config/axios-interceptor'
import { getProfile } from '../auth/auth.api'
import { formatVND } from '@/shared/utils/ultils'
import SModal from '@/components/shared/Modal/SModal'
import { CButton, CModalBody, CModalFooter } from '@coreui/react-pro'
import { IBiDatCuoc } from '@/shared/model/biDatCuoc.model'
import { mapBiDatCuocGroup } from '@/shared/enumeration/ResponseStatus'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'
import KyQuay from './KyQuay'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { getEntities } from '../HistoryManagement/historyManagement.api'
import { log } from 'console'
import { historySelectors } from '../HistoryManagement/historyManagement.reducer'
import { IHistory } from '@/shared/model/history.model'
import { IGameName } from '@/shared/model/gameName.model'
import { IGameSidebar, INewGameSidebar } from '@/shared/model/gameSidebar.model'
import Pk10 from './Pk10'
import LotteBet from './LotteBe'
import EsportF1 from './EsportF1'

const Game = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate, params } = useRouter()


  const [game, setGame] = useState<IGameName | null>(null)

  const getGame = async (id: number) => {
    try {
      const response = await axios.get('/game/' + id)
      setGame(response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    getGame(Number(params?.id) ?? 1)
  }, [params?.id])
  console.log(params?.id);
  
  if (!game) return null

  // // Game PK
  // if (game.group === 1) {
  //   return <Pk10 game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 2) {
  //   return <LotteBet game={game} />
  // }

  // // EsportF1
  // if (game.group === 3) {
  //   return <EsportF1 game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 3) {
  //   return <LotteBet game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 4) {
  //   return <LotteBet game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 5) {
  //   return <LotteBet game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 6) {
  //   return <LotteBet game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 7) {
  //   return <LotteBet game={game} />
  // }
  // // Game Lotte Bet
  // if (game.group === 8) {
  //   return <LotteBet game={game} />
  // }

  // // Game Lotte Bet
  // if (game.group === 9) {
  //   return <LotteBet game={game}/>
  // }

  // // Game Lotte Bet
  // if (game.group === 10) {
  //   return <LotteBet game={game}/>
  // }

  // // Game Lotte Bet
  // if (game.group === 11) {
  //   return <LotteBet game={game}/>
  // }

  // // Game Lotte Bet
  // if (game.group === 12) {
  //   return <LotteBet game={game}/>
  // }

  // // Game Lotte Bet
  // if (game.group === 13) {
  //   return <LotteBet game={game}/>
  // }

  // // Game Lotte Bet
  // if (game.group === 14) {
  //   return <LotteBet game={game}/>
  // }

  return <></>
}

export default Game
